import {
  createStore
} from 'vuex';
const store = createStore({
  state() {
    return {
      articalListInfo: {},
      articalActiveId: REGION_INFO.regionName === 'smx' ? 1 : 0,
      fontLoading: true,
      subtopicListInfo: {},
      showSubtopicInfo: false,
      subIconScrollLeft: 0
    };
  },
  mutations: {
    setArticalListInfo(state, info) {
      state.articalListInfo = info;
    },
    setArticalActiveId(state, id) {
      state.articalActiveId = id;
    },
    setFontLoading(state, load){
      state.fontLoading = load;
    },
    setSubtopicInfo(state, info){
      state.subtopicListInfo = info;
    },
    setShowSubtopicInfo(state, isShow){
      state.showSubtopicInfo = isShow;
    },
    setSubIconScrollLeft(state, left){
      state.subIconScrollLeft = left;
    }
  }
});
export default store;
