<template>
  <div class="avue-top">
    <div class="container">
      <img :src="topLeftBg" alt="" class="top-bg">
      <div class="dashboard-logo">
        <img :src="topLogo" alt="">
      </div>
      <div class="buildings-bg">
        <img :src="topBuilds" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top',
  components: {},
  computed: {
    topBuilds(){
      return REGION_INFO.img.topBuilds;
    },
    topLeftBg(){
      return REGION_INFO.img.topLeftBg;
    },
    topLogo(){
      return REGION_INFO.img.topLogo;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.avue-top {
  background-image: linear-gradient(180deg, #e32033 0%, #c30e17 85%);
  box-shadow: 0 0 20px 0 rgba(106, 1, 1, 0.2);
  padding: 0;
  width: 100%;
  overflow: hidden;
  .container {
    width: 85%;
    margin: 0 auto;
    height: 80px;
    position: relative;
    line-height: 80px;
    text-align: left;
    .top-bg {
      position: absolute;
      height: 80px;
      left:-105px;
    }

    .dashboard-logo {
      display:inline-block;
      position: relative;
      img {
        height: 45px;
      }
    }

    .buildings-bg {
      position: absolute;
      bottom: 0;
      right: 250px;
      height: 90px;

      img {
        height: 100%;
      }
    }
  }
}

.el-icon-postcard,
.el-icon-menu,
.el-icon-switch-button {
  padding-left: 15px;
}

.el-icon-postcard,
.el-icon-menu,
.el-icon-switch-button {
  height: 34px;
  line-height: 34px;
}
</style>
