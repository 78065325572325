<template>
  <div class="list-wrapper">
    <div v-if="list.length">
      <ArticalCard v-for="(artical,index) in list" :key="index" data-testid="article-card" :artical="artical" @to-detail="toDetail"/>
    </div>
    <div v-else class="list-empty">
      <img :src="empty">
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import ArticalCard from '../../components/ArticalCard';
import empty from '../../../public/img/empty.png';

export default {
  name: 'List',
  components: {
    ArticalCard
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  emits: ['to-detail'],
  data(){
    return{
      empty
    };
  },
  methods: {
    toDetail(){
      this.$emit('to-detail');
    }
  }
};
</script>

<style lang="scss" scoped>
.list-wrapper{
  background-color: #fff;
  .list-empty {
    img {
      width: 80%;
      display: block;
      margin: 0 auto;
      margin-top: 50%;
    }

    span {
      font-size: 14px;
      color: #999;
    }
  }
}

</style>
