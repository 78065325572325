import {
  createRouter,
  createWebHistory
} from 'vue-router';

import SMXArticalList from './components/ArticalList.vue';
import ZKArticalList from './customViews/zk/ArticalList.vue';
import ArticalLeaderList from './components/ArticalLeaderList.vue';
import ArticalDetail from './components/ArticalDetail.vue';
const routes = [
  {
    path: '/detail',
    name: 'ArticalDetail',
    meta: {
      title: ''
    },
    component: ArticalDetail
  }, 
  {
    path: '/leader-list',
    name: 'ArticalLeaderList',
    meta: {
      title: '领导关注'
    },
    component: ArticalLeaderList
  }, 
  {
    path: '/',
    meta: {
      title: REGION_INFO.pageName
    },
    component: REGION_INFO.regionName === 'smx' ? SMXArticalList : ZKArticalList
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
