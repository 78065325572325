<template>
  <div>
    <Top v-if="!isMobile"/>
    <div :class="!isMobile?'pc-css':''">
      <div class="detail-container">
        <div v-if="!isMobile" class="qr-code">
          <vue-qr 
            :text="qrUrl" 
            :size="200"
            :margin="12"
            :logo-src="qrLogo"
            :logo-scale="0.2"
          />
          <p>扫描二维码</p>
          <p>手机阅读和分享</p>
        </div>
        <div>
          <img class="share-img" :src="detailInfo.thumbnail">
          <div class="main">
            <p class="title">{{detailInfo.title}}</p>
            <div class="info" :class="userRole==='leader'?'no-number':''">
              <span v-show="userRole==='common'" class="number">
                <EyeOutlined/> {{detailInfo.clickNumber}}&nbsp;&nbsp;
                <ClockCircleOutlined/> {{detailInfo.createTime}}
              </span>
              <div class="btn-group">
                <button class="font-size-small" @click="setFontSize(0)">小</button>
                <button class="font-size-middle" @click="setFontSize(1)">中</button>
                <button class="font-size-big" @click="setFontSize(2)">大</button>
              </div>
            </div>
            <div class="content" v-html="detailInfo.articleContent"></div>
            <p v-if="detailInfo.source" class="source">文章来源： {{detailInfo.source}}</p>
            <img :src="footQR" alt="">
            <img :src="footState" alt="">
          </div>
          <div v-if="isShowDownload" class="footer" data-testid="download" @click="download">
            <img :src="logoNews" alt="">
            <button class="download">立即下载</button>
          </div>
        </div>
        <div v-if="contentLoading" class="loading-img">
          <img :src="loadingImg" class="icon">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sendArticalInfoToApp} from '../utils/quickhybrid';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import axios from '../axios';
import {EyeOutlined, ClockCircleOutlined} from '@ant-design/icons-vue';
import footState from '../../public/img/foot-state.png';
import loadingImg from '../../public/img/loading.gif';
import Top from './Top.vue';
import smxLogo from '../../public/img/logo.png';
import zkLogo from '../../public/img/zk-logo.png';
export default {
  name: 'ArticalDetail',
  components: {
    EyeOutlined,
    ClockCircleOutlined,
    Top,
    vueQr
  },
  data() {
    return {
      detailInfo: {},
      footState,
      loadingImg,
      contentLoading: true,
      qrLogo: REGION_INFO.regionName === 'smx' ? smxLogo : zkLogo,
      logoNews: REGION_INFO.img.articalFootDownloadLogo,
      footQR: REGION_INFO.img.articalFootQr
    };
  },
  computed: {
    isShowDownload() {
      return navigator.userAgent.indexOf('SMXXYBrowser') < 0 && navigator.userAgent.indexOf('smxxy') < 0 && this.isMobile;
    },
    isMobile(){
      const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      return agents.some(item => navigator.userAgent.indexOf(item) >= 0);
    },
    qrUrl(){
      return window.location.href;
    },
    userRole(){
      return this.$cookies.isKey('gtTalkUserRole') ? this.$cookies.get('gtTalkUserRole') : 'common';
    }
  },
  mounted() {
    this.getDetails();
    window.scroll(0, 0);
    //与APP约定，当应用内打开时，有分享功能，APP会拦截此路由跳转。
    this.$nextTick(() => {
      if (navigator.userAgent.indexOf('SMXXYBrowser') >= 0) {
        location.href = 'smxxy://action/web/showShare';
      }
      this.fontSize = this.$cookies.isKey('gtTalkFontSize') ? this.$cookies.get('gtTalkFontSize') : 1;
      this.setFontSize(this.fontSize - 0);
    });
  },
  methods: {
    getDetails() {
      axios.get('/cmsArticleData/articleIds', {
        params: {
          id: this.$route.query.id
        }
      }).then(res => {
        setTimeout(() => {
          this.contentLoading = false;
        }, 1000);
        this.detailInfo = res.data.data;
        document.title = this.detailInfo.title;
        sendArticalInfoToApp(this.detailInfo, this.detailInfo.title);
      })
        .catch(() => {
          this.$sensors.track('gtzw_article_DetailFailed', {
            gtzw_article_id: this.$route.query.id
          });
        });
    },
    download() {
      location.href = `${REGION_INFO.url}/h5/download/?source=article`;
    },
    setFontSize(index) {
      this.fontSize !== index && this.$cookies.set('gtTalkFontSize', index, -1);
      const contentDom = document.getElementsByClassName('content')[0];
      contentDom.style.fontSize = `${14 + index * 4 }px`;
      contentDom.style.lineHeight = `${24 + index * 6 }px`;
      this.isMobile && (contentDom.style.fontFamily = 'SourceHanSerifCN');
      Array.from(document.getElementsByClassName('btn-group')[0].children).forEach((dom, i) => {
        if(i === index){
          dom.style.backgroundColor = '#E32033';
          dom.style.color = '#fff';
        }else{
          dom.style.backgroundColor = '#fff';
          dom.style.color = '#666';
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pc-css{
  margin:0 auto;
  background-color: #fff;
  width: 1200px;
}
.detail-container {
  padding-bottom: 40px;
  max-width: 800px;
  margin-right: auto; 
  margin-left: auto;
  position: relative;
  background-color: #fff;
  .qr-code{
    position: absolute;
    right: -170px;
    top: 473px;
    padding: 5px;
    width:130px;
    height: 186px;
    background-color:#C92826;
    border-radius:6px;
    p{
      margin: 0;
      color:#fff;
      font-size: 14px;
    }
  }
  .share-img {
    display: none;
    width: 600px;
  }

  .main {
    padding: 20px 16px;

    .title {
      font-family: 'SourceHanSerifCN';
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      color: #333;
    }
    .info.no-number{
      justify-content: flex-end;
    }
    .info {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      color: #999;
      align-items: center;

      .btn-group {

        button {
          border: 1px solid #E32033;
          background-color: #fff;
          width: 45px;
          height: 25px;
          color:#666;
        }

        button:focus {
          outline: none;
        }

        :not(:last-child) {
          border-right: none;
        }
      }
    }

    .content {
      font-size: 14px;
      letter-spacing: 1px;
      color: #666;
      text-align: left;
      padding: 10px 0;
    }

    .source {
      text-align: left;
      color: #888;
    }
  }

  .footer {
    width: 100%;
    max-width: 800px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    display: flex;
    clear: both;
    justify-content: space-between;
    padding-right: 16px;
    box-shadow: 0px -1px 3px #ddd;
    height: 6%;
    min-height: 60px;

    img {
      display: inline-block;
      width: 60%;
      object-fit: scale-down;
      object-position: left;
    }

    .download {
      width: 20%;
      min-width: 110px;
      height: 50%;
      line-height: 50%;
      background-image: linear-gradient(135deg, #fc5556 0%, #e40d22 100%);
      border-radius: 30px;
      margin: auto 0;
      font-size: 16px;
      color: #fff;
      border: none;
    }
  }
  .loading-img{
    position: fixed;
    top: 0;
    left:0;
    z-index:100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    .icon{
      width: 10%;
    }
  }
}
</style><style>
.detail-container img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 5px;
}
</style>
