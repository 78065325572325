<template>
  <div class="list-container">
    <a-tabs v-if="!showSubtopicList" :default-active-key="activeId + ''" @change="callback">
      <a-tab-pane v-for="option in options" :key="option.key" data-testid="article-column" :tab="option.tab">
        <Carousel class="topic-img" autoplay>
          <div v-for="topic in topicInfo" :key="topic.id" class="topic-content" @click="toArticalDetail(topic)">
            <img :src="topic.img">
            <div class="topic-title">{{topic.title}}</div>
          </div>
        </Carousel>
        <div class="main-sub" @mouseover="showArrow=true" @mouseleave="showArrow=false">
          <div :id="`subIcon${option.key}`" class="sub-icon">
            <div v-for="sub in options[activeId].subtopic" :key="sub.id" class="subtopic" @click="goSubtopic(sub)">
              <img :src="sub.icon" class="icon">
              <p class="text">{{sub.name}}</p>
            </div>
          </div>
          <div v-if="showArrow&&activeId===2" class="arrows last" @click="switchPage('last')">
            <CaretLeftOutlined :style="{ fontSize: '24px', color:'#bbb'}"/>
          </div>
          <div v-if="showArrow&&activeId===2" class="arrows next" @click="switchPage('next')">
            <CaretRightOutlined :style="{ fontSize: '24px', color:'#bbb'}"/>
          </div>
        </div>
        <List :list="articalList[activeId]" @to-detail="toDetail"/>
      </a-tab-pane>
    </a-tabs>
    <div v-if="showSubtopicList">
      <div class="sub-header">
        <img :src="returnImg" @click="closeSubList()">
        {{activeSubstopic.name}}
      </div>
      <List :list="substopicList" @to-detail="toDetail"/>
    </div>
    <div v-if="loading" class="loading-img">
      <img :src="loadingImg" class="icon">
    </div>
  </div>
</template>

<script>
import {sendArticalInfoToApp} from '../../utils/quickhybrid';
import {Tabs, Carousel} from 'ant-design-vue';
import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons-vue';
import List from './List';
import axios from '../../axios';
import loadingImg from '../../../public/img/loading.gif';
import returnImg from '../../../public/img/return.png';
export default {
  name: 'ArticalList',
  components: {
    'a-tabs': Tabs,
    'a-tab-pane': Tabs.TabPane,
    List,
    Carousel,
    CaretLeftOutlined,
    CaretRightOutlined
  },
  data () {
    return {
      pageNum: 1,
      options: _config_.column,
      topicInfo: _config_.topicImg,
      articalList: [[], [], []],
      activeId: 0,
      loadingImg,
      contentLoading: false,
      showSubtopicList: false,
      returnImg,
      activeSubstopic: {},
      substopicList: [],
      articalScrollHeight: 0,
      showArrow: false
    };
  },
  computed: {
    articalListInfo () {
      return this.$store.state.articalListInfo;
    },
    articalActiveId () {
      return this.$store.state.articalActiveId;
    },
    fontLoading(){
      return this.$store.state.fontLoading;
    },
    loading(){
      return this.fontLoading || this.contentLoading;
    },
    subtopicListInfo () {
      return this.$store.state.subtopicListInfo;
    },
    showSubtopicInfo () {
      return this.$store.state.showSubtopicInfo;
    },
    subIconScrollLeft () {
      return this.$store.state.subIconScrollLeft;
    },
    isMobile(){
      const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      return agents.some(item => navigator.userAgent.indexOf(item) >= 0);
    }
  },
  beforeMount(){
    if(this.showSubtopicInfo || this.articalListInfo.length){
      this.subtopicListInfo && (this.substopicList = this.subtopicListInfo.list);
      this.subtopicListInfo && (this.activeSubstopic = this.subtopicListInfo.activeObj);
      this.showSubtopicList = this.showSubtopicInfo;
      this.articalList = this.articalListInfo;
      this.activeId = this.articalActiveId;
      this.subtopicListInfo && (this.pageNum = this.subtopicListInfo.pageNum);
    } else {
      this.getArticleList(this.activeId);
      this.contentLoading = true;
    }
  },
  mounted (){
    console.log('_config_', _config_);
    this.windowHeight = document.documentElement.clientHeight || document.body.clientHeight || 1304;
    window.onscroll = () => {
      this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop > 0 && (this.articalList[this.activeId].scrollTop = scrollTop);
      //*1.1为了兼容不同的浏览器，有些浏览器顶部菜单栏，不计入windowHeight
      if (this.showSubtopicList && scrollTop + this.windowHeight * 1.1 > this.scrollHeight
        && this.articalScrollHeight !== this.scrollHeight) {
        this.pageNum++;
        this.getSubtopicList(this.activeSubstopic, this.pageNum);
        this.articalScrollHeight = this.scrollHeight;
      }
    };
  },
  methods: {
    callback (key) {
      this.activeId = key - 0;
      this.articalList[this.activeId].length === 0 && this.getArticleList(this.activeId);
      this.$sensors.track('gtzw_article_category_click', {
        gtzw_article_category: `${this.activeId + 85 }`
      });
    },
    async getArticleList (activeId) {
      const getAllList = this.options[activeId].subtopic.map(i => this.getArticle(i.id, 1, 1));
      try {
        const result = await Promise.allSettled(getAllList);
        const lists = result.map(res => res.value).flat()
          .sort((a, b) => b.contentId - a.contentId);
        setTimeout(() => {
          this.contentLoading = false;
        }, 3500);
        this.articalList[activeId].push(...lists);
        activeId === 0 && sendArticalInfoToApp(this.articalList[activeId][0], REGION_INFO.pageName);
      } catch (e) {
        console.error(e);
      }
    },
    async getArticle (id, pageNum, pageSize){
      const result = await axios.get('/cmsArticle/cmsCategoryId', {
        params: {
          id,
          pageNum,
          pageSize,
          userId: 0
        }
      });
      return result.data.data;
    },
    toDetail () {
      this.$store.commit('setArticalListInfo', this.articalList);
      this.$store.commit('setArticalActiveId', this.activeId);
      this.$store.commit('setSubtopicInfo', {
        list: this.substopicList,
        activeObj: this.activeSubstopic,
        pageNum: this.pageNum
      });
      this.$store.commit('setShowSubtopicInfo', this.showSubtopicList);
    },
    goSubtopic (sub) {
      this.$sensors.track('gtzw_article_category_click', {
        gtzw_article_category: `${sub.id }`
      });
      this.activeSubstopic = sub;
      this.getSubtopicList(sub, 1);
      const scrollLeft = document.getElementById(`subIcon${this.activeId}`).scrollLeft || 0;
      this.$store.commit('setSubIconScrollLeft', scrollLeft);
    },
    async getSubtopicList (sub, pageNum) {
      const result = await this.getArticle(sub.id, pageNum, 10);
      pageNum === 1 && (this.substopicList = result);
      pageNum > 1 && this.substopicList.push(...result);
      this.showSubtopicList = true;
    },
    toArticalDetail (topic) {
      this.$sensors.track('gtzw_article_content_click', {
        gtzw_article_id: `${topic.id }`,
        gtzw_article_category: `${topic.categoryId }`
      });
      this.toDetail();
      const detailUrl = `${window.location.href}detail`;
      if (navigator.userAgent.indexOf('SMXXYBrowser') >= 0) {
        location.href = `smxxy://page/web/browser?url=${ detailUrl }?id=${ topic.id}`;
      } else if(this.isMobile){
        this.$router.push({
          name: 'ArticalDetail',
          query: {
            id: topic.id
          }
        });
      } else{
        window.open(`${detailUrl }?id=${ topic.id}`);
      }
    },
    closeSubList(){
      this.showSubtopicList = false;
      this.$nextTick(() => {
        this.subIconScrollLeft > 0 && (document.getElementById(`subIcon${this.activeId}`).scrollLeft = this.subIconScrollLeft);
      });
    },
    switchPage(type){
      const dom = document.getElementById(`subIcon${this.activeId}`);
      type === 'last' && dom.scrollLeft > 0 && (dom.scrollLeft -= 100);
      type === 'next' && dom.scrollLeft < (dom.scrollWidth - dom.clientWidth) && (dom.scrollLeft += 100);
    }
  }
};
</script>

<style lang="scss">
.list-container {
  .ant-tabs-nav {
    font-size: 16px;
    .ant-tabs-tab-active,
    .ant-tabs-tab:hover {
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #c00e16;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-bar,
  .ant-tabs-top-bar {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 99;
  }

  .ant-tabs-top-content,
  .ant-tabs-content,
  .ant-tabs-content-animated {
    padding-top: 49px;
  }

  .ant-tabs-nav,
  .ant-tabs-nav-animated {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;

    div {
      width: 100%;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    width: 33%;
    margin: 0;
  }
  .loading-img{
    position: absolute;
    top: 0;
    z-index:100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    .icon{
      width: 10%;
    }
  }
  .topic-img{
    width: 100%;
    img{
      width: 100%;
      height: 25vh;
      display:block;
      object-fit:cover;
      cursor: pointer;
    }
   .topic-content{
     position: relative;
   }
    .topic-title{
      position: absolute;
      bottom: 0;
      margin: 0;
      width: 100%;
      color: #FFF;
      font-size: 16px;
      font-weight: bold;
      background-color: rgba(0,0,0,0.5);
      text-align: left;
      line-height: 38px;
      padding-left: 5px ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .subtopic{
    display: inline-block;
    padding:15px 5px;
    width: 19%;
    min-width: 70px;
    max-width: 150px;
    cursor: pointer;
    .icon{
      width: 85%;
      max-width: 90px;
    }
    .text{
      margin: 3px;
    }
  }
  .sub-header{
    position: relative;
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    img{
      position: absolute;
      left: 10px;
      top: 10px;
      width: 24px;
    }
  }
  .main-sub{
    position: relative;
    .sub-icon{
      width: auto;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      margin:0 10px;
    }
    .arrows{
      cursor: pointer;
      position: absolute;
      top:30%;
    }
    .arrows.next{
      right: 0;
    }
    .arrows.last{
      left: 0;
    }
  }
}
</style>
