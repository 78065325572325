<template>
  <div class="list-container">
    <a-tabs :default-active-key="activeId + ''" @change="callback">
      <a-tab-pane v-for="option in options" :key="option.key" data-testid="article-column" :tab="option.tab">
        <div v-if="articalList[activeId].list.length">
          <ArticalCard
            v-for="(artical,index) in articalList[activeId].list"
            :key="index"
            data-testid="article-card"
            :artical="artical"
            @to-detail="toDetail"
          />
        </div>
        <div v-else class="list-empty">
          <img :src="empty">
          <span>暂无数据</span>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div v-if="loading" class="loading-img">
      <img :src="loadingImg" class="icon">
    </div>
  </div>
</template>

<script>
import {sendArticalInfoToApp} from '../utils/quickhybrid';
import {Tabs} from 'ant-design-vue';
import ArticalCard from './ArticalCard';
import axios from '../axios';
import empty from '../../public/img/empty.png';
import loadingImg from '../../public/img/loading.gif';
export default {
  name: 'ArticalList',
  components: {
    'a-tabs': Tabs,
    'a-tab-pane': Tabs.TabPane,
    ArticalCard
  },
  data () {
    return {
      empty,
      options: [{
        key: '1',
        tab: '政务动态'
      }, {
        key: '0',
        tab: '局委风采'
      }, {
        key: '2',
        tab: '甘棠独家'
      }],
      articalList: [{list: [], scrollTop: 0, pageNum: 1},
        {list: [], scrollTop: 0, pageNum: 1},
        {list: [], scrollTop: 0, pageNum: 1}],
      activeId: 0,
      articalScrollHeight: 0,
      loadingImg,
      contentLoading: false
    };
  },
  computed: {
    articalListInfo () {
      return this.$store.state.articalListInfo;
    },
    articalActiveId () {
      return this.$store.state.articalActiveId;
    },
    fontLoading(){
      return this.$store.state.fontLoading;
    },
    loading(){
      return this.fontLoading || this.contentLoading;
    }
  },
  beforeMount(){
    this.articalListInfo.length && (this.articalList = this.articalListInfo);
    this.activeId = this.articalActiveId;
    this.$nextTick(() => {
      window.scroll(0, this.articalList[this.activeId].scrollTop);
    });
  },
  mounted () {
    this.$cookies.set('gtTalkUserRole', 'common', -1);
    this.windowHeight = document.documentElement.clientHeight || document.body.clientHeight || 1304;
    this.getArticleList(this.activeId);
    window.onscroll = () => {
      this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop > 0 && (this.articalList[this.activeId].scrollTop = scrollTop);
      //*1.1为了兼容不同的浏览器，有些浏览器顶部菜单栏，不计入windowHeight
      if (scrollTop + this.windowHeight * 1.1 >= this.scrollHeight && this.articalScrollHeight !== this.scrollHeight) {
        this.articalList[this.activeId].pageNum++;
        this.getArticleList(this.activeId);
        this.articalScrollHeight = this.scrollHeight;
      }
    };
    !this.articalList[this.activeId].list.length && (this.contentLoading = true);
  },
  methods: {
    callback (key) {
      this.activeId = key - 0;
      this.articalList[this.activeId].list.length === 0 && this.getArticleList(this.activeId);
      this.$nextTick(() => {
        window.scroll(0, this.articalList[this.activeId].scrollTop);
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      });
      this.$sensors.track('gtzw_article_category_click', {
        gtzw_article_category: `${this.activeId + 85 }`
      });
    },
    getArticleList (id) {
      axios.get('/cmsArticle/cmsCategoryId', {
        params: {
          id: id + 85,
          pageNum: this.articalList[id].pageNum,
          pageSize: 10,
          userId: 0
        }
      }).then(res => {
        setTimeout(() => {
          this.contentLoading = false;
        }, 3500);
        this.articalList[id].list.push(...res.data.data);
        id === 1 && this.articalList[id].pageNum === 1 && sendArticalInfoToApp(this.articalList[id].list[0], REGION_INFO.pageName);
        this.$nextTick(() => {
          this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          if (this.articalList[id].pageNum === 1 && res.data.data.length === 10) {
            if (this.scrollHeight <= this.windowHeight) {
              this.articalList[id].pageNum++;
              this.getArticleList(id);
            }
          }
        });
      })
        .catch(() => {
          this.$sensors.track('gtzw_articler_ListFailed');
        });
    },
    toDetail () {
      this.$store.commit('setArticalListInfo', this.articalList);
      this.$store.commit('setArticalActiveId', this.activeId);
    }
  }
};
</script>

<style lang="scss">
.list-container {
  .ant-tabs-nav {
    font-size: 16px;
    .ant-tabs-tab-active,
    .ant-tabs-tab:hover {
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #c00e16;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .list-empty {
    img {
      width: 80%;
      display: block;
      margin: 0 auto;
      margin-top: 50%;
    }

    span {
      font-size: 14px;
      color: #999;
    }
  }

  .ant-tabs-bar,
  .ant-tabs-top-bar {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 99;
  }

  .ant-tabs-top-content,
  .ant-tabs-content,
  .ant-tabs-content-animated {
    padding-top: 49px;
  }

  .ant-tabs-nav,
  .ant-tabs-nav-animated {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;

    div {
      width: 100%;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    width: 33%;
    margin: 0;
  }
  .loading-img{
    position: absolute;
    top: 0;
    z-index:100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    .icon{
      width: 10%;
    }
  }
}
</style>
