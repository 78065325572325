import {
  createApp
} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import sensors from './assets/sensors';
import './interceptor';
import VueCookies from 'vue-cookies';
const app = createApp(App);
// const VConsole = require("vconsole/dist/vconsole.min.js");
// var vConsole = new VConsole();
app.config.performance = true;
// process.env.NODE_ENV === 'test' && app.use(vConsole);
// process.env.NODE_ENV === 'dev' && app.use(vConsole);
app.use(router);
app.use(store);
app.use(sensors);
app.config.globalProperties.$sensors = sensors;
app.config.globalProperties.$cookies = VueCookies;
app.mount('#app');
