<template>
  <div class="list-container">
    <div v-if="articalList.length">
      <ArticalCard v-for="(artical,index) in articalList" :key="index" data-testid="article-card" :artical="artical"/>
    </div>
    <div v-else class="list-empty">
      <img :src="empty">
      <span>暂无数据</span>
    </div>
    <div v-if="loading" class="loading-img">
      <img :src="loadingImg" class="icon">
    </div>
    <div class="foot-img">
      <img :src="footLeftImg" class="left">
      <img :src="footRightImg" class="right">
      <div class="main">
        ————<img :src="bookLogo" class="logo">————
        <p class="text">下期更精彩</p>
      </div>
    </div>
  </div>
</template>

<script>
import {sendArticalInfoToApp} from '../utils/quickhybrid';
import ArticalCard from './ArticalCard';
import axios from '../axios';
import empty from '../../public/img/empty.png';
import loadingImg from '../../public/img/loading.gif';
import footLeftImg from '../../public/img/foot-build-left.png';
import footRightImg from '../../public/img/foot-build-right.png';
import bookLogo from '../../public/img/book-logo.png';
export default {
  name: 'ArticalLeaderList',
  components: {
    ArticalCard
  },
  data () {
    return {
      empty,
      articalList: [],
      loadingImg,
      contentLoading: false,
      footLeftImg,
      footRightImg,
      bookLogo
    };
  },
  computed: {
    fontLoading(){
      return this.$store.state.fontLoading;
    },
    loading(){
      return this.fontLoading || this.contentLoading;
    }
  },
  mounted () {
    this.getArticleList();
    this.$cookies.set('gtTalkUserRole', 'leader', -1);
    !this.articalList.length && (this.contentLoading = true);
  },
  methods: {
    getArticleList () {
      axios.get('/cmsArticle/cmsCategoryId', {
        params: {
          id: 93,
          pageNum: 1,
          pageSize: 20,
          userId: 0
        }
      }).then(res => {
        setTimeout(() => {
          this.contentLoading = false;
        }, 3500);
        this.articalList = res.data.data;
        sendArticalInfoToApp(this.articalList[0], '领导关注');
      });
    }
  }
};
</script>

<style lang="scss">
.list-container {
  .ant-tabs-nav {
    font-size: 16px;
    .ant-tabs-tab-active,
    .ant-tabs-tab:hover {
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #c00e16;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .list-empty {
    img {
      width: 80%;
      display: block;
      margin: 0 auto;
      margin-top: 50%;
    }

    span {
      font-size: 14px;
      columns: #999;
    }
  }

  .ant-tabs-bar,
  .ant-tabs-top-bar {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 99;
  }

  .ant-tabs-top-content,
  .ant-tabs-content,
  .ant-tabs-content-animated {
    padding-top: 49px;
  }

  .ant-tabs-nav,
  .ant-tabs-nav-animated {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;

    div {
      width: 100%;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    width: 33%;
    margin: 0;
  }
  .loading-img{
    position: absolute;
    top: 0;
    z-index:100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    .icon{
      width: 10%;
    }
  }
  .foot-img{
    position: relative;
    height:calc(100vh - 624px);
    min-height: 100px;
    background-color: #fff;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .left {
      height: 100%;
      max-height: 350px;
      position: absolute;
      left: -5%;
      bottom: 0;
    }
    .right {
      height: 100%;
      max-height: 350px;
      position: absolute;
      right: -5%;
      bottom: 0;
    }
    .main{
      width: 100%;
      color: #eee;
    }
    .logo{
      width:13%;
      max-width: 75px;
      position: relative;
      margin: 0 20px;
    }
    .text{
      color: #bbb;
      padding-top:10px;
      margin: 0;
    }
  }
}
</style>
