<template>
  <div v-if="artical.contentId" class="article-wrapper">
    <img v-if="artical.thumbnail" data-testid="article-img" class="img" :src="artical.thumbnail" alt="">
    <img v-else data-testid="article-empty-img" class="empty-img" :src="picSeat" alt="">
    <div class="summary">
      <p data-testid="article-title" :class="isMobile?'title':'title pc'" @click="toDetail(artical.contentId)">{{artical.title}}</p>
      <div v-show="userRole==='common'" class="info">
        <span class="visit-number">
          <EyeOutlined/> {{artical.clickNumber}}</span>
        <span class="create-time">
          <ClockCircleOutlined/> {{artical.createTime}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {EyeOutlined, ClockCircleOutlined} from '@ant-design/icons-vue';
import picSeat from '../../public/img/icon_pic_seat.png';
export default {
  name: 'ArticalCard',
  components: {
    EyeOutlined,
    ClockCircleOutlined
  },
  props: {
    artical: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['to-detail'],
  data () {
    return {
      picSeat
    };
  },
  computed: {
    isMobile(){
      const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      return agents.some(item => navigator.userAgent.indexOf(item) >= 0);
    },
    userRole(){
      return this.$cookies.isKey('gtTalkUserRole') ? this.$cookies.get('gtTalkUserRole') : 'common';
    },
    articalDetailUrl(){
      const baseUrl = `${window.location.href}detail`;
      return baseUrl;
    }
  },
  mounted () {
  },
  methods: {
    toDetail (id) {
      const eventName = this.userRole === 'common' ? 'gtzw_article_content_click' : 'gtzw_reference_content_click';
      this.$sensors.track(eventName, {
        gtzw_article_id: `${this.artical.contentId }`,
        gtzw_article_category: `${this.artical.cmsCategoryId }`
      });
      this.$emit('to-detail');
      if (navigator.userAgent.indexOf('SMXXYBrowser') >= 0) {
        location.href = `smxxy://page/web/browser?url=${ this.articalDetailUrl }?id=${ id}`;
      } else if(this.isMobile){
        this.$router.push({
          name: 'ArticalDetail',
          query: {
            id
          }
        });
      } else{
        window.open(`${this.articalDetailUrl }?id=${ id}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.article-wrapper {
  display: flex;
  padding: 12px;
  height: 104px;
  border-bottom: 1px solid #eee;

  .img {
    margin-right: 13px;
    width: 100px;
    object-fit: cover;
  }
  .empty-img {
    margin-right: 13px;
    width: 100px;
    padding: 25px;
    border: 1px solid rgb(250, 250, 248);
  }
  .summary {
    width: 100%;
    .title {
      height: 54px;
      font-family: 'SourceHanSerifCN';
      line-height: 27px;
      font-size: 18px;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; //控制行数
      overflow: hidden;
      cursor: pointer;
      &:hover{
        color: #F87676;
      }
    }

    .title.pc{
      font-family: Arial!important;
    }

    .info {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      color: #999;
    }
  }
}
</style>
