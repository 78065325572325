import quickhybrid from './quickhybrid.js';
import quickhybridNative from './quickhybrid.native';

quickhybridNative(quickhybrid);

quickhybrid.config({});

export function sendArticalInfoToApp(info, title) {
  return new Promise((resolve, reject) => {
    quickhybrid.callApi({
      name: 'share_content',
      module: 'control',
      data: {
        thumbnail: info.thumbnail,
        tail: REGION_INFO.pageName,
        title,
        iconTail: REGION_INFO.img.iconTail
      },
      success (data) {
        resolve(data);
      },
      error (error) {
        reject(error);
      }
    });
  });
}

quickhybrid.error(function (e) {
  console.info('========== quick 验证失败 =========');
  console.error('======= 错误信息 ======：', JSON.stringify(e));
});

quickhybrid.ready(function () {
  console.info('========== quick 验证成功 =========');
});

export default quickhybrid;
